<template>
    <div class="content">
        <div class="link">
            <span class="home" @click="handleClick('/')">首页 > </span>
            <span class="news" @click="handleClick('/news')">公司新闻 > </span>
            <span class="cancel">又一家新店开业 I 嘉汇万邻超市凤翔社区店</span>
        </div>
        <div class="main">
            <div class="wrap">
                <img src="../assets/images/img_07.png">
                <img src="../assets/images/img_08.png">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Detail01",
        methods: {
            handleClick(path) {
                this.$router.push(path);
                this.$EventBus.$emit('reset')
            }
        }
    }
</script>

<style lang="scss" scoped>
.content{
    width: 100%;
    .link{
        width: 70%;
        padding-left: 15%;
        padding-bottom: 20px;
        font-size: 18px;
        .home, .news{
            cursor: pointer;
        }
        .cancel{
            color: #999;
        }
    }
    .main{
        width: 100%;
        text-align: center;
        .wrap{
            display: inline-block;
            width: 70%;
        }
        img{
            width: 100%;
        }
    }
}
</style>